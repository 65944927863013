.modal {
  label {
    font-size: .83125rem;
    font-weight: 600;
  }
  input, select {
    font-size: .894rem;
  }
}

.modal-dialog.modal-xxl {
  max-width: 90% !important;
}