.dataTables_length,.dataTables_filter {
  margin-bottom: 20px !important;
}

.datatable {
  th {
    font-weight: 600 !important;
  }

  td {
    padding: 8px ;
  }

  td,th {
    text-align: center !important;
  }

  .td-left {
    text-align: left !important;
    p {
      font-size: .894rem;
    }
  }

  .dropdown-toggle::after, svg {
    color: #fff !important;
    font-size: .8em;
  }

  .dropdown-menu {
    li {
      cursor: pointer !important;
    }
    li a svg{
      color: #000 !important;
    }

    --cui-dropdown-link-active-bg: #475468;
  }

  .alert {
    padding: 3px 5px;
    border-radius: 0;
    margin: 0;
  }
}
