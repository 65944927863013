.card {
  label {
    font-weight: 600;
    margin-bottom: 5px;
  }
  label, input, select {
    font-size: .894rem;
  }
  input {
    padding: 8px 15px;
    border: 1px solid rgba(24,28,33,0.1);
  }

}