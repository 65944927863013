/* You can add global styles to this file, and also import other style files */
// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import styles with default layout.
@import "~@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "~@coreui/chartjs/scss/coreui-chartjs";

// perfect scrollbar theming for use with [perfectScrollbar] directive
//@import '~perfect-scrollbar/css/perfect-scrollbar.css';

@import "layout";

// Some temp fixes
@import "fixes";

// Prism.js
@import "examples";

// If you want to add something do it here
@import "custom";

.sidebar-nav .nav-link.active {
  color: #fff !important;
  background-color: #26B4FF !important;
}

.nav-link {
  white-space: break-spaces !important;
}

.option {
  font-size: .8em !important;
}

.cursor-pointer {
  cursor: pointer;
}

@import "./components/card";
@import "./components/modal";
@import "./components/datatable";
@import "./operations/client-approved";